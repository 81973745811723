<template>
    <section class="bg-login">
        <div class="container full-height p-4 fl-y-cr ">
            <s-form role="form" @submit="onSubmit" class="card pos-r login-card py-4">
                <div class="row m-0 fl-j-sb">
                    <div class="col-4 pr-0 fl-x-cc">
                        <!--                        <img src="../../assets/img/logo.png" class="img-fluid" alt="#">-->
                        <h3 class="font-weight-bold lt-space-1">TESUTO</h3>
                    </div>
<!--                    <div class="col-6 pl-0 fl-x-cc">-->
<!--                        &lt;!&ndash;                        <img src="../../assets/img/nav-logo.png" class="img-fluid" alt="#">&ndash;&gt;-->
<!--                        Tesuto Logo 2-->
<!--                    </div>-->
                </div>
                <div class="inner-card bg-layer">
                    <h4 class="font-weight-bold">Login</h4>
                    <p class="mb-3 info-text">Login with your Credential</p>
                    <validated-input v-model="model.username" class="login-field" type="text" label="Username"/>
                    <validated-input v-model="model.password" class="login-field" type="password" label="Password"/>
                    <div class="bg-danger p-3" style="text-align: center;" v-if="errorMessage"
                         v-html="errorMessage"></div>
                    <div class="mt-3 text-right">
                        <btn color="primary mr-2 px-4" icon-alignment="right" icon="fa fa-arrow-right"
                             :loading="loading" loading-text="Validating Credential"
                             text="Login"/>
                    </div>
                </div>
            </s-form>
        </div>
    </section>

    <!--    <div class="full-page" title="">-->
    <!--        <div class="card bs-5 w-30r">-->
    <!--            <h2 class="text-primary"> Login</h2>-->
    <!--            <p class="text-primary">Login with your credentials</p>-->
    <!--            <s-form ref="loginForm">-->
    <!--                <validated-input icon="fa fa-user" name="Username" label="Username" v-model="model.username"-->
    <!--                                 :disabled="loading" :rules="rules.username"/>-->
    <!--                <validated-input icon="fa fa-lock" name="Password" label="Password" type="password" v-model="model.password"-->
    <!--                                 :disabled="loading" :rules="rules.password"/>-->

    <!--                <div class="card bg-danger mb-2" v-if="errorMessage" v-html="errorMessage"></div>-->

    <!--                <btn @click="submitClicked" icon="fa fa-lock" text="Login" loading-text="Validating..." size="block"-->
    <!--                     :disabled="loading" :loading="loading"/>-->
    <!--            </s-form>-->
    <!--        </div>-->
    <!--    </div>-->
</template>

<script>
import urls from '@/data/urls';
import { mapActions } from 'vuex';
import axios from 'secure-axios';

export default {
    name : 'Login',
    data () {
        return {
            loginUrl : urls.auth.login,
            loading  : false,
            model    : {
                username : '',
                password : ''
            },
            mount        : true,
            errorMessage : '',
            rules        : {
                username : {
                    required : true
                },
                password : {
                    required : true
                }
            }
        };
    },
    methods : {
        ...mapActions(['setUser']),
        async submitClicked () {
            const that = this;
            that.loading = true;
            const response = await axios.form(urls.auth.login, that.model);
            const json = response.data;
            if (!json) {
                this.loginError();
            } else if (json.error === false) {
                this.loginClicked(json);
            } else {
                this.loginError();
            }
            that.loading = false;
        },
        formError (json) {
            this.$refs.loginForm.setErrors(json.errors);
            this.$notify('Please fix the issue.', 'Error', {
                type : 'danger'
            });
        },
        loginClicked (response) {
            if (response.user) {
                const redirectUrl = sessionStorage.getItem('redirectPath');
                this.setUser({
                    ...response.user
                });
                this.$router.push(redirectUrl || { path : '/' });
            }
            localStorage.clear();
        },
        loginError (msg) {
            this.errorMessage = 'Invalid Credentials';
        }
    }
};
</script>
<style>
.full-page {
    height: 100vh;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.bg-login {
    /*background-image: url("../../assets/img/bg/bg-login-1.jpg");*/
    background-image: url("../../assets/img/bg/login-bg-3.jpg");
    background-size: cover;
    background-position: center;
    position: relative;
}

.bg-login:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    /*background-color: #00000042;*/
    /*for bg 2*/
    /*background-color: rgba(0,153,255,0.15);*/
    /*for bg-3*/
    background-color: rgba(0, 153, 255, 0.68);
    /*for bg 4*/
    /*background-color: rgba(0, 153, 255, 0.15);*/
    background-size: cover;
    /*background-position: left;*/
    background-position: 11% 18%;
    z-index: 0;
}

.login-card {
    width: 380px;
    /*background: #033556;*/
    /*background: var(--color-primary-700);*/
    /*background: var(--color-primary-600);*/
    /*background: rgb(4, 213, 255);*/
    /*background: linear-gradient(100deg, rgba(4, 213, 255, 1) 12%, rgba(0, 153, 255, 1) 88%);*/
    background-color: var(--bg-8);
    padding: 10px;
}

.inner-card {
    /*background: #d3deebfa;*/
    padding: 15px;
    border-radius: 5px;
}
</style>
